// src/components/Footer.js v1.1
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4 mt-5">
      <Container>
        <Row>
          <Col md={4} className="mb-3 mb-md-0">
            <h5>EffortlessGadgets</h5>
            <p className="footer-text">
              Your ultimate guide to the latest tech and gadgets.
            </p>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/blog" className="footer-link">Latest Reviews</a></li>
              <li><a href="/game" className="footer-link">Play Game</a></li>
              <li><a href="/contact" className="footer-link">Contact Us</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Connect With Us</h5>
            <div className="social-icons">
              <a href="https://twitter.com" className="text-light me-3"><i className="fab fa-twitter"></i></a>
              <a href="https://facebook.com" className="text-light me-3"><i className="fab fa-facebook"></i></a>
              <a href="https://instagram.com" className="text-light me-3"><i className="fab fa-instagram"></i></a>
            </div>
          </Col>
        </Row>
        <hr className="mt-4 bg-secondary" />
        <div className="text-center footer-copyright">
          <small>&copy; {new Date().getFullYear()} EffortlessGadgets. All rights reserved.</small>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;