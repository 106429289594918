// src/components/ContactForm.js
import React, { useState, useRef } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [verified, setVerified] = useState(false);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const captchaRef = useRef(null);

  const handleVerificationSuccess = (token) => {
    setToken(token);
    setVerified(true);
  };

  const handleCaptchaExpire = () => {
    setToken(null);
    setVerified(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!verified) {
      setStatus({
        type: 'danger',
        message: 'Please complete the captcha verification'
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          captchaToken: token
        }),
      });

      let result;
      const responseText = await response.text();
      
      try {
        result = responseText ? JSON.parse(responseText) : {};
      } catch (error) {
        console.error('JSON parsing error:', error);
        throw new Error('Invalid server response');
      }

      if (!response.ok) {
        throw new Error(result.error || `Server error: ${response.status}`);
      }

      setStatus({
        type: 'success',
        message: result.message || 'Message sent successfully!'
      });

      // Reset form
      setFormData({ name: '', email: '', subject: '', message: '' });
      setVerified(false);
      setToken(null);
      if (captchaRef.current) {
        captchaRef.current.resetCaptcha();
      }

    } catch (error) {
      console.error('Form submission error:', error);
      setStatus({
        type: 'danger',
        message: error.message || 'An error occurred. Please try again later.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="contact-form">
      {status.message && (
        <Alert variant={status.type} className="mb-3">
          {status.message}
        </Alert>
      )}

      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({...formData, name: e.target.value})}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({...formData, email: e.target.value})}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          value={formData.subject}
          onChange={(e) => setFormData({...formData, subject: e.target.value})}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          value={formData.message}
          onChange={(e) => setFormData({...formData, message: e.target.value})}
          required
        />
      </Form.Group>

      <HCaptcha
        ref={captchaRef}
        sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
        onVerify={handleVerificationSuccess}
        onExpire={handleCaptchaExpire}
      />

      <Button 
        variant="primary" 
        type="submit" 
        disabled={isSubmitting || !verified}
        className="mt-3 w-100"
      >
        {isSubmitting ? 'Sending...' : 'Send Message'}
      </Button>
    </Form>
  );
};

export default ContactForm;