// src/pages/Blog.js
import React from 'react';

const blogPosts = [
  {
    id: 1,
    title: "Latest iPhone Review",
    excerpt: "An in-depth look at Apple's newest flagship device...",
    image: "https://via.placeholder.com/300x200",
    date: "2024-03-22",
    category: "Smartphones"
  },
  {
    id: 2,
    title: "Best Gaming Laptops 2024",
    excerpt: "Our top picks for gaming laptops this year...",
    image: "https://via.placeholder.com/300x200",
    date: "2024-03-20",
    category: "Laptops"
  },
  {
    id: 3,
    title: "Smart Home Gadgets Guide",
    excerpt: "Transform your home with these smart devices...",
    image: "https://via.placeholder.com/300x200",
    date: "2024-03-18",
    category: "Smart Home"
  }
];

const Blog = () => {
  return (
    <div className="container py-5">
      <div className="text-center mb-5">
        <h2>Latest Gadget Reviews</h2>
        <p className="text-muted">Stay updated with the newest tech releases and reviews</p>
      </div>

      <div className="row">
        {blogPosts.map(post => (
          <div key={post.id} className="col-md-4 mb-4">
            <div className="card gadget-card h-100">
              <img src={post.image} className="card-img-top" alt={post.title} />
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span className="badge bg-primary">{post.category}</span>
                  <small className="text-muted">{post.date}</small>
                </div>
                <h5 className="card-title">{post.title}</h5>
                <p className="card-text">{post.excerpt}</p>
                <button className="btn btn-outline-primary">Read More</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center mt-4">
        <button className="btn btn-primary">Load More Posts</button>
      </div>
    </div>
  );
};

export default Blog;
