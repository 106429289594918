// src/components/Header.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <Navbar bg="white" expand="lg" className="shadow-sm">
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <i className="fas fa-mobile-alt me-2 text-primary"></i>
          <span className="fw-bold">EffortlessGadgets</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link 
              as={NavLink} 
              to="/"
              className={({ isActive }) => isActive ? 'active' : ''}
            >
              Home
            </Nav.Link>
            <Nav.Link 
              as={NavLink} 
              to="/blog"
              className={({ isActive }) => isActive ? 'active' : ''}
            >
              Blog
            </Nav.Link>
            <Nav.Link 
              as={NavLink} 
              to="/game"
              className={({ isActive }) => isActive ? 'active' : ''}
            >
              Game
            </Nav.Link>
            <Nav.Link 
              as={NavLink} 
              to="/contact"
              className={({ isActive }) => isActive ? 'active' : ''}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
