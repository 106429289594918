// src/pages/Game.js
import React, { useState, useEffect, useMemo } from 'react';
import confetti from 'canvas-confetti';
import './Game.css';

const Game = () => {
  const [cards, setCards] = useState([]);
  const [flipped, setFlipped] = useState([]);
  const [solved, setSolved] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isGameComplete, setIsGameComplete] = useState(false);

  const gadgets = useMemo(() => [
    { id: 1, name: 'Smartphone' },
    { id: 2, name: 'Laptop' },
    { id: 3, name: 'Tablet' },
    { id: 4, name: 'Smartwatch' },
    { id: 5, name: 'Headphones' },
    { id: 6, name: 'Camera' },
  ], []);

  useEffect(() => {
    const duplicatedGadgets = [...gadgets, ...gadgets]
      .sort(() => Math.random() - 0.5)
      .map((card, index) => ({ ...card, uniqueId: index }));
    setCards(duplicatedGadgets);
  }, [gadgets]);

  const fireConfetti = () => {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 }
    };

    function fire(particleRatio, opts) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio)
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    fire(0.2, {
      spread: 60,
    });

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  useEffect(() => {
    if (solved.length === gadgets.length) {
      setIsGameComplete(true);
      fireConfetti();
    }
  }, [solved, gadgets.length]);

  const handleClick = (index) => {
    if (flipped.length === 0) {
      setFlipped([index]);
    } else if (flipped.length === 1) {
      setDisabled(true);
      if (cards[flipped[0]].id === cards[index].id) {
        setSolved([...solved, cards[index].id]);
        setFlipped([]);
      } else {
        setFlipped([flipped[0], index]);
        setTimeout(() => setFlipped([]), 1000);
      }
      setTimeout(() => setDisabled(false), 1000);
    }
  };

  return (
    <div className="container py-5">
      <h2 className="text-center mb-4">Memory Game</h2>

      <div className="row g-4">
        {cards.map((card, index) => (
          <div key={card.uniqueId} className="col-md-2 col-sm-3 col-4">
            <div
              className={`card memory-game-card ${
                flipped.includes(index) || solved.includes(card.id) ? 'flipped' : ''
              }`}
              onClick={() => !disabled && !flipped.includes(index) && handleClick(index)}
            >
              <div className="card-inner">
                <div className="card-front">?</div>
                <div className="card-back">{card.name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isGameComplete && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '2rem',
          borderRadius: '10px',
          boxShadow: '0 0 20px rgba(0,0,0,0.2)',
          zIndex: 1000,
          textAlign: 'center'
        }}>
          <h3>
            <i className="fas fa-trophy" style={{ color: '#FFD700', marginRight: '10px' }}></i>
            Congratulations!
            <i className="fas fa-star" style={{ color: '#FFD700', marginLeft: '10px' }}></i>
          </h3>
          <p>You've matched all the pairs!</p>
          <button 
            className="btn btn-primary"
            onClick={() => window.location.reload()}
          >
            <i className="fas fa-redo-alt" style={{ marginRight: '5px' }}></i>
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default Game;
