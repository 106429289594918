// src/pages/Home.js v1.2
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/EG_logo.png';

const Home = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className={`logo-container ${scrolled ? 'scrolled' : ''}`}>
        <img src={logo} alt="EffortlessGadgets Logo" className="eg-logo" />
      </div>

      <section className="hero-section text-center">
        <div className="container">
          <h1>Welcome to EffortlessGadgets</h1>
          <p className="lead">Your ultimate guide to the latest tech and gadgets</p>
          <Link to="/blog" className="btn btn-light btn-lg mt-3">Explore Gadgets</Link>
        </div>
      </section>

      <div className="container">
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="card gadget-card h-100">
              <div className="card-body text-center">
                <i className="fas fa-mobile-alt fa-3x mb-3 text-primary"></i>
                <h3>Latest Reviews</h3>
                <p>Discover in-depth reviews of the newest gadgets</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card gadget-card h-100">
              <div className="card-body text-center">
                <i className="fas fa-gamepad fa-3x mb-3 text-primary"></i>
                <h3>Fun Games</h3>
                <p>Test your knowledge with our tech-themed games</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card gadget-card h-100">
              <div className="card-body text-center">
                <i className="fas fa-users fa-3x mb-3 text-primary"></i>
                <h3>Community</h3>
                <p>Join our tech-loving community</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;